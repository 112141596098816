import React from 'react'
import { Toggle, Heading } from '@roar/components'
import { Box } from 'rebass/styled-components'
import RichTextRenderer from '../components/RichTextRenderer'
import { graphql } from 'gatsby'
import Layout from './Layout'

const AccordionWrapper = (props) => {
  const { items = [], headline, style = {}, description } = props

  return (
    <Box {...style}>
      {headline && (
        <Heading size="h3" as="h2" mb={[2]}>
          {headline}
        </Heading>
      )}
      {description && (
        <Box mb={[2, 2, 4]}>
          <RichTextRenderer content={description} />
        </Box>
      )}

      {items &&
        items.length &&
        items.map((item) => {
          const layout = { ...item, showHeading: false }
          return (
            <Toggle
              variant="accordion"
              headline={() => (
                <Heading size="h5" as="h3">
                  {item.headline}
                </Heading>
              )}
            >
              <Layout layout={[layout]} />
            </Toggle>
          )
        })}
    </Box>
  )
}

export default AccordionWrapper

export const layoutAccordion = graphql`
  fragment LayoutAccordion on ContentfulLayoutAccordion {
    __typename
    id
    name
    headline
    description {
      raw
      # references {
      #   __typename
      #   ... on ContentfulHeading {
      #     ...HeadingFields
      #   }
      #   ... on ContentfulContentGeneric {
      #     ...ContentGeneric
      #   }
      # }
    }
    items {
      ...ContentGeneric
    }
    style {
      mt
      mb
      ml
      mr
    }
  }
`
